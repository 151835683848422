<!--
 * @Descripttion:
 * @version:
 * @Author: 张圳
 * @Date: 2023-11-19 15:53:32
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-03-20 17:01:26
-->
<template>
  <div class="container-warp">
    <back-title>课程详情</back-title>
    <!-- 线下课程内容--开始 -->
    <div class="bottom-box" style="margin-top: 16px">
      <el-tabs v-model="activeName" :style="{'--font-Color' : fontColor}">
          <el-tab-pane label="课程介绍" name="0">
            <div class="name-title">
              <com-title>{{courseIntroduceInfo.name}}</com-title>
              <div style="margin-left: 12px">课程编号 {{courseIntroduceInfo.courseCode||'—'}}</div>
            </div>
            <div class="teacher-box">
              <div>带教老师</div>
              <el-select v-model="instructorId" style="width: 274px;margin:0 32px 0 16px" placeholder="请选择" filterable :disabled="courseIntroduceInfo.status!=1">
                <el-option
                  v-for="item in teacherList"
                  :key="item.userId"
                  :label="item.userName"
                  :value="item.userId"
                  @click.native="_selectTeacher(item)"
                >
                </el-option>
              </el-select>
              <el-button
                v-if="courseIntroduceInfo.status==1"
                type="primary"
                plain
                :style="{'--lock-btn': $store.state.settings.theme,'color':$store.state.settings.theme}"
                @click="_startStudy(2)">
                开始学习
              </el-button>
              <el-button
                v-if="courseIntroduceInfo.status==2"
                type="primary"
                plain
                :style="{'--lock-btn': $store.state.settings.theme,'color':$store.state.settings.theme}"
                @click="_startStudy(3)">
                学习已完成
              </el-button>
            </div>
            <div style="margin-top:24px;">
              <com-title>课程描述</com-title>
              <div class="describe-text">{{courseIntroduceInfo.description||'—'}}</div>
            </div>
            <div style="margin-top:24px;">
              <com-title>课程介绍内容</com-title>
              <div class="describe-text">
              <div v-if="courseIntroduceInfo.introduce">
                <span v-html="courseIntroduceInfo.introduce"></span>
              </div>
              <div v-else> — </div>
            </div>
            </div>
        </el-tab-pane>
        <el-tab-pane label="课程资料" name="1">
          <div class="content">
            <div class="empty-box" v-if="courseMaterialData.length === 0">
              <PlaceholderMap text="暂无课程资料噢~" />
            </div>
            <div class="item-content" v-for="(item,index) in courseMaterialData" :key="index">
              <div class="item-left">
                <img src="@/assets/img/text.png" alt="" />
                <div class="hover-name">{{item.fileName}}</div>
              </div>
              <div class="item-right">
                <el-button type="text" @click="downloadFile(item)">下载</el-button>
                <el-button type="text" @click="_previewFileHandle(item,index)">查看</el-button>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 图片预览--开始 -->
    <PreviewFile
      v-if="previewFileShow"
      :previewFileShow="previewFileShow"
      :currentFile="currentFile"
      ref="previewFile"
    ></PreviewFile>
    <!-- 图片预览--结束 -->

  </div>
</template>

<script>
import backTitle from '@/components/backTitle/index.vue'
import comTitle from '@/components/comTitle/index.vue'
import PlaceholderMap from '@/components/placeholderMap/index.vue'
import { getStudyArrangeCourseDetailApi, getUserOfflineSignInApi } from '@/api/study'
import PreviewFile from '@/components/previewFile/index.vue'
import fileType from '@/mixins/fileType'
import { getUserListApi } from '@/api/api'
export default {
  mixins: [fileType],
  components: {
    backTitle,
    comTitle,
    PlaceholderMap,
    PreviewFile
  },
  data () {
    return {
      fontColor: this.$store.state.settings.theme,
      instructorId: '', // 带教人员id
      instructorName: '', // 带教人员姓名
      teacherList: [],
      activeName: '0',
      courseIntroduceInfo: {}, // 课程介绍
      courseMaterialData: [], // 课程资料
      previewFileShow: false
    }
  },
  created () {
    this.fontColor = this.$store.state.settings.theme
    this._getUserList()
    if (this.$route.query.courseId) {
      this._getOnlineCourseDetail(this.$route.query.courseId)
    }
  },
  methods: {
    // 选择带教人员
    _selectTeacher (item) {
      this.instructorId = item.userId// 带教人员id
      this.instructorName = item.userName// 带教人员姓名
    },
    // 开始学习
    _startStudy (status) {
      if (!this.instructorId) {
        return this.$message.error('请选择带教人员！')
      }
      const params = {
        id: this.courseIntroduceInfo.scheduleId,
        status: status,
        instructorId: this.instructorId, // 带教人员id
        instructorName: this.instructorName// 带教人员姓名

      }
      getUserOfflineSignInApi({ ...params }).then((res) => {
        if (res.code === 200) {
          this._getOnlineCourseDetail(this.$route.query.courseId)
        }
      })
    },
    // 获取组织架构人员列表
    _getUserList () {
      getUserListApi({ pageSize: 0, userPostStatus: 4 }).then((res) => {
        if (res.code === 200) {
          this.teacherList = res.data.data
        }
      })
    },
    // 获取详情
    _getOnlineCourseDetail (courseId, type) {
      getStudyArrangeCourseDetailApi({ courseId: courseId }).then((res) => {
        if (res.code === 200) {
          this.courseIntroduceInfo = JSON.parse(JSON.stringify(res.data.courseIntroduction))
          this.courseMaterialData = res.data.courseMaterials
          this.instructorId = this.courseIntroduceInfo?.instructorId
          this.instructorName = this.courseIntroduceInfo?.instructorName
        }
      })
    },
    // 查看课程资料
    _previewFileHandle (row, index) {
      // 列表预览
      if (row.fileType === 'image' || row.fileType === 'video') {
        this.previewFileShow = true
        this.currentFile = row
      } else {
        const previewUrl = this.getPreviewUrl(row)// 处理文件预览格式
        window.open(previewUrl)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.el-tabs__item.is-active{
    color:var(--font-Color)!important;
  }
  /deep/.el-tabs__item{
    color: #737373!important;
  }
.container-warp {
  padding: 16px 24px;
  min-height: 900px;
  background-color: #ffffff;
  border-radius: 4px;
}
/deep/.title-box {
  margin-bottom: 16px;
}
.name-title {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #666666;
}
.teacher-box {
  display: flex;
  align-items: center;
  margin-top: 16px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.9);
}
.describe-text{
  margin-top: 8px;
  font-size: 14px;
  color: #4D4D4D;
}
.content {
  background: url("../../../assets/img/data-bg.png");
  padding: 12px 24px;
  height:  calc(100vh - 440px);
  overflow: auto;
  .item-content {
    display: flex;
    align-content: center;
    justify-content: space-between;
    border-bottom: 1px solid #f3f3f3;
    padding: 12px 0;

    &:last-of-type {
      border: none;
    }
    .item-left {
      display: flex;
      align-items: center;
      img {
        width: 38px;
        height: 38px;
        margin-right: 16px;
      }
    }
  }
}
</style>
